
.position_logo_header{
  display: flex;
  flex-wrap: wrap;
  position:fixed;
  width: 100%;
  top:0;
  display:block;
}
 
.header_logo {
  flex-direction: column;
  padding: 20px;
  text-align: center;
  /*background-color:#f1f1f1;*/
  background-color:white;
  color: white;
  float: auto;

  
}

 .stage_logo {
   flex: 30%;
   height: 5vh;
   width: 20vh;
   margin: auto;
   position: relative;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   perspective: 9999px;
   transform-style: preserve-3d;
}
 .layer_logo {
   width: 5vh;
   height: 5vh;
   position: absolute;
   transform-style: preserve-3d;
   animation: ಠ_ಠ 5s infinite alternate ease-in-out -7.5s;
   animation-fill-mode: forwards;
   transform: rotateY(40deg) rotateX(33deg) translateZ(0);
}
 .layer_logo:after {
   font: 50px 'Lovers Quarrel', 'Pacifico', 'Kaushan Script', Futura, 'Roboto', 'Trebuchet MS', Helvetica, sans-serif;
   content: 'PlanetryVilage'; 
   white-space: pre;
   text-align: center;
   height: 5vh;
   width: 5vh;
   position: absolute;
   top: 0px;
   color: #f5f5f5;
   letter-spacing: -2px;
   text-shadow: 4px 0 10px rgba(0, 0, 0, .13);
}
 .layer_logo:nth-child(1):after {
   transform: translateZ(0px);
}
 .layer_logo:nth-child(2):after {
   transform: translateZ(-1.5px);
}
 .layer_logo:nth-child(3):after {
   transform: translateZ(-3px);
}
 .layer_logo:nth-child(4):after {
   transform: translateZ(-4.5px);
}
 .layer_logo:nth-child(5):after {
   transform: translateZ(-6px);
}
 .layer_logo:nth-child(6):after {
   transform: translateZ(-7.5px);
}
 .layer_logo:nth-child(7):after {
   transform: translateZ(-9px);
}
 .layer_logo:nth-child(8):after {
   transform: translateZ(-10.5px);
}
 .layer_logo:nth-child(9):after {
   transform: translateZ(-12px);
}
 .layer_logo:nth-child(10):after {
   transform: translateZ(-13.5px);
}
 .layer_logo:nth-child(11):after {
   transform: translateZ(-15px);
}
 .layer_logo:nth-child(12):after {
   transform: translateZ(-16.5px);
}
 .layer_logo:nth-child(13):after {
   transform: translateZ(-18px);
}
 .layer_logo:nth-child(14):after {
   transform: translateZ(-19.5px);
}
 .layer_logo:nth-child(15):after {
   transform: translateZ(-21px);
}
 .layer_logo:nth-child(16):after {
   transform: translateZ(-22.5px);
}
 .layer_logo:nth-child(17):after {
   transform: translateZ(-24px);
}
 .layer_logo:nth-child(18):after {
   transform: translateZ(-25.5px);
}
 .layer_logo:nth-child(19):after {
   transform: translateZ(-27px);
}
 .layer_logo:nth-child(20):after {
   transform: translateZ(-28.5px);
}
 .layer_logo:nth-child(n+10):after {
   -webkit-text-stroke: 3px rgba(0, 0, 0, .25);
}
 .layer_logo:nth-child(n+11):after {
   -webkit-text-stroke: 15px dodgerblue;
   text-shadow: 6px 0 6px #00366b, 5px 5px 5px #002951, 0 6px 6px #00366b;
}
 .layer_logo:nth-child(n+12):after {
   -webkit-text-stroke: 15px #0077ea;
}
 .layer_logo:last-child:after {
   -webkit-text-stroke: 17px rgba(0, 0, 0, .1);
}
 .layer_logo:first-child:after {
   color: #fff;
   text-shadow: none;
} 
 @keyframes ಠ_ಠ {
   100% {
     transform: rotateY(-0deg) rotateX(-0deg);  
  }
}