


.demoPage {
    background-color: black;
    color: white;
}


.firstLast {
	height:400px;
	color:black;
	text-align:center;
    background-color: white;
}
 

:root{scrollbar-color:#4b4b4b #eee;scrollbar-width:thin};
::-webkit-scrollbar-track-piece{background-color:#fff;-webkit-border-radius:0}
::-webkit-scrollbar{width:8px;height:8px}
::-webkit-scrollbar-thumb{height:50px;background-color:#999;-webkit-border-radius:4px;outline:2px solid #fff;outline-offset:-2px;border:2px solid #fff}


@media only screen and (max-width: 600px) {
  :root{scrollbar-color:#4b4b4b #eee;scrollbar-width:none};
::-webkit-scrollbar-track-piece{background-color:#fff;-webkit-border-radius:0}
::-webkit-scrollbar{width:0px;height:0px}
::-webkit-scrollbar-thumb{height:0px;background-color:#999;-webkit-border-radius:4px;outline:2px solid #fff;outline-offset:-2px;border:2px solid #fff}

}
