.pages {
    /*height: 386px; */
    width: 100%;
    overflow-y:auto;
}

.page {
    height: 100%;
    width: 100%;
    /*user-select: none;
    touch-action: none;*/
}

